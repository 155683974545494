import jqXHR = JQuery.jqXHR;

/**
 * Listen to keyup event on document
 */
document.addEventListener('change', function (e) {
    // All address field names
    const addressElements = [
        'street',
        'house_number',
        'house_number_addition',
        'postal_code',
        'city',
    ]

    const target = e?.target as HTMLInputElement;
    const form = target?.form as HTMLFormElement;

    // If the address is filled, validate it when a change is detected on an address field
    if (form
        && form.id === 'checkout'
        && addressElements.includes(target.name)
        //@ts-ignore
        && form.elements.street.value
        //@ts-ignore
        && form.elements.house_number.value
        //@ts-ignore
        && form.elements.postal_code.value
        //@ts-ignore
        && form.elements.city.value
    ) {

        //@ts-ignore
        $.request('onValidateAddress', {
            form: `#${form.id}`,
            flash: true,

            // Triggers if request errors (not 200 response)
            error: function (object: jqXHR, status: string, error: any) {

                // Throw flash messages for validation errors
                if (error.status === 406) {

                    // Retrieve errors from response
                    const errors = JSON.parse(error.response);
                    let messages: any[] = [];

                    // Loop through errors and set data attributes
                    Object.keys(errors).forEach(name => {
                        const element = document.querySelector('*[form="' + form.id + '"][name="' + name + '"]');

                        // Filters out non-elements (e.g. when refreshing partials)
                        if (!element) return;

                        element.setAttribute('data-validation-message', errors[name]);
                        element.setAttribute('data-validation-error', '');

                        // Push error to messages array if it is not already in array
                        if (!messages.includes(errors[name])) {
                            messages.push(errors[name]);
                        }
                    });

                    // Throw flash messages
                    messages.forEach(message => {
                        //@ts-ignore
                        $.oc.flashMsg({text: message, class: 'error'})
                    });
                } else {

                    // Run default error function (throws other than 406 errors)
                    this.error(object, status, error)
                }
            },

            // Triggers if request does not error (200 response)
            success: function (data: any) {

                // Unset all errors
                const errorElements = document.querySelectorAll('*[form="' + form.id + '"][data-validation-error]');
                errorElements.forEach(element => {
                    element.removeAttribute('data-validation-error');
                    element.removeAttribute('data-validation-message');
                });

                // Run default success function
                this.success(data);
            }
        });
    }
})