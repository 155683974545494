/**
 * Focuses on the first visible search bar in the document
 */
window.focusSearchBar = (): void => {
    const searchBars = document.querySelectorAll(".search-bar__input input") as NodeListOf<HTMLElement>;

    console.log(searchBars);

    if (!searchBars) return;

    for (const searchBar of searchBars) {
        if (isHidden(searchBar)) continue;

        window.toggleOverlay("search-results", "modal", false, searchBar);
    }
};

/**
 * Refresh and open the shopping cart
 *
 * TODO: reverse order: open cart, then update contents with a skeleton loading animation
 */
window.openShoppingCart = (): void => {
    // @ts-ignore
    oc.request(null, "onRefreshCart", {
        update: {
            "Cart::default": "#page-side-menu",
        },
        success: function (data: any) {
            this.success(data).done(function () {
                window.toggleOverlay("cart", "side-menu");
            });
        },
    });
};

/**
 * Check if an element is hidden
 *
 * @param element any HTML element
 *
 * @returns Whether an element is hidden
 */
const isHidden = (element: HTMLElement): boolean => {
    return element.offsetParent === null;
};
