// @ts-ignore
import ChangeEvent = JQuery.ChangeEvent;

// @ts-ignore
window.$ = window.jQuery = require("jquery");

let request: NodeJS.Timeout | null;

/**
 * Filter and sort products
 */
const filterProducts = (e: ChangeEvent | Event) => {
    const target = e?.target as HTMLInputElement;
    const form = target?.form as HTMLFormElement;

    const names = ['price_range[min]', 'price_range[max]', 'sort'];

    // Check if form and input field are correct
    if (form && form.id.includes('filters') && names.includes(target.name)) {

        const currentPage = new URLSearchParams(window.location.search).get('page');

        //@ts-ignore
        $.request('onFilter', {
            data: {'page': currentPage},
            form: `#${form.id}`,
            flash: true,
            success: function (data: any) {
                this.success(data).done(function () {
                    request = null;
                });
            }
        });
    }
};

/**
 * Select2 handler for the select2 product filter inputs
 */
$(() => {
    // @ts-ignore
    $('[data-hldr-select-filters]').on('change', function (e) {
        filterProducts(e);
    });
});


/**
 * EventListener for native input field changes
 */
document.addEventListener('change', function (e) {

    // Clear timeout if within 500 milliseconds a new change was made
    if (request != null)
        clearTimeout(request);

    request = setTimeout(
        () => {
            filterProducts(e)
        },
        750
    );
});